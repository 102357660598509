import React from "react";

const MyBuyers = () => {
  return (
    <div>
      <h1>MyBuyers</h1>
    </div>
  );
};

export default MyBuyers;
